import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

import * as dat from "dat.gui";
import * as CANNON from "cannon-es";
import Stats from "stats.js";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, SplitText);

// import px from 'textures/environmentMaps/5/px.png';

import cannonDebugger from "cannon-es-debugger";

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

////////// RENDERER
const renderer = new THREE.WebGLRenderer({ canvas: document.querySelector(".canvas"), antialias: true, alpha: true });
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setPixelRatio(1);

renderer.outputEncoding = THREE.sRGBEncoding;
// renderer.toneMapping = THREE.ACESFilmicToneMapping;
// renderer.toneMappingExposure = 1.25;

// FPS STATS
// var stats = new Stats();
// var container = document.createElement("div");
// document.body.appendChild(container);
// container.appendChild(stats.dom);

////////// SCENE
const scene = new THREE.Scene();

////////// LIGHTS
const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.camera.far = 30;
directionalLight.shadow.camera.left = -7;
directionalLight.shadow.camera.top = 7;
directionalLight.shadow.camera.right = 7;
directionalLight.shadow.camera.bottom = -7;
directionalLight.position.set(3, 5, 3);
scene.add(directionalLight);

////////// CAMERA
var camera_start_y = window.innerWidth > 570 ? 0 : -5;
var camera_start_z = window.innerWidth > 570 ? 10 : 20;

const camera = new THREE.PerspectiveCamera(60, sizes.width / sizes.height, 0.1, 500);
camera.position.set(0, camera_start_y, camera_start_z);
// camera.lookAt(0, camera.position.y, -10000);

scene.add(camera);
////////// CONTROLS
// const controls = new OrbitControls(camera, renderer.domElement);
// controls.enableDamping = true;
// controls.target = new THREE.Vector3(0, 1.5, 0);
// controls.enableZoom = false

////////// DAT GUI
// const gui = new dat.GUI();
// const debugObject = {};

// debugObject.cutRope = () => {
//   console.log(springs);
//   springs = [];
// };
// gui.add(debugObject, "cutRope");
// gui.close();

////////// SOUNDS
const hitSound = new Audio("/sounds/hit.mp3");

var collision_count = 0;
var choir0 = document.querySelector(".choir0");
var choir1 = document.querySelector(".choir1");
var choir2 = document.querySelector(".choir2");
var can_choir = false;
var impactThreshold = window.innerWidth > 1024 ? 0.1 : 0;

const playChoir = (collision) => {
  const impactStrength = collision.contact.getImpactVelocityAlongNormal();
  if (impactStrength > impactThreshold) {
    if (!can_choir) return;
    collision_count++;
    console.log(collision_count, impactStrength);

    if (window.innerWidth > 1024) {
      hitSound.volume = 0.3;
      hitSound.currentTime = 0;
      hitSound.play();
    }
    if (collision_count == 4) {
      choir0.volume = 0.65;
      choir0.play();
      console.log("play armistice");
    }
    if (collision_count == 8) {
      choir1.volume = 0.65;
      choir1.play();
      console.log("play ");
    }
    if (collision_count == 12) {
      choir2.volume = 0.65;
      choir2.play();
      console.log("play humphreys");
    }
  }
};

////////// TEXTURES

var format = ".png";

const loader = new THREE.CubeTextureLoader();

var img_length = 29;

var unique_arr = [];
while (unique_arr.length < 13) {
  var r = Math.floor(Math.random() * img_length) + 1;
  if (unique_arr.indexOf(r) === -1) unique_arr.push(r);
}

var textureCubes = [];

for (let i = 0; i < unique_arr.length; i++) {
  var textureCube = loader.load([
    "/textures/photos/image_" + unique_arr[i] + ".jpg",
    "/textures/photos/image_" + unique_arr[i] + ".jpg",
    "/textures/photos/image_" + unique_arr[i] + ".jpg",
    "/textures/photos/image_" + unique_arr[i] + ".jpg",
    "/textures/photos/image_" + unique_arr[i] + ".jpg",
    "/textures/photos/image_" + unique_arr[i] + ".jpg",
  ]);
  textureCube.encoding = THREE.sRGBEncoding;
  //   textureCube.mapping = THREE.CubeRefractionMapping;
  textureCube.mapping = THREE.CubeReflectionMapping;

  textureCubes.push(textureCube);
}

////////// PHYSICS //////////
const world = new CANNON.World();
world.broadphase = new CANNON.SAPBroadphase(world);
// world.allowSleep = true;
world.gravity.set(0, -20, 0);

// Default material
const defaultMaterial = new CANNON.Material("default");
const defaultContactMaterial = new CANNON.ContactMaterial(defaultMaterial, defaultMaterial, {
  friction: 0.1,
  restitution: 0.7,
});
world.defaultContactMaterial = defaultContactMaterial;

// cannonDebugger(scene, world.bodies);

////////// UTILS
const objectsToUpdate = [];

// Create sphere
const sphereGeometry = new THREE.SphereGeometry(1, 20, 20);
const sphereMaterial = new THREE.MeshStandardMaterial({
  metalness: 0.5,
  roughness: 0.5,
});

var spheres_c_arr = [];

const createSphere = (radius, position) => {
  // Three.js mesh
  const mesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
  mesh.castShadow = true;
  mesh.scale.set(radius, radius, radius);
  mesh.position.copy(position);
  if (window.innerWidth > 1024) {
    scene.add(mesh);
  }

  // Cannon.js body
  const shape = new CANNON.Sphere(radius * 1);

  const body = new CANNON.Body({
    mass: 0,
    position: new CANNON.Vec3(0, 3, 0),
    shape: shape,
    material: defaultMaterial,
  });
  body.position.copy(position);
  body.addEventListener("collide", playChoir);
  world.addBody(body);
  spheres_c_arr.push(body);

  // Save in objects
  objectsToUpdate.push({ mesh, body });
};

createSphere(0.1, { x: 10, y: 3, z: 0 });

// Create box

// mirrorGeometry.translate(  4, 0,0 )

var springs = [];
var lines = [];
var mirrors = [];

// var mirror_body = new CANNON.Body({ mass: 1 });
// var shapeA = new CANNON.Box(new CANNON.Vec3(1,1,1));
// mirror_body.addShape(shapeA, new CANNON.Vec3(2,0,0), new CANNON.Quaternion());
// var shapeB = new CANNON.Box(new CANNON.Vec3(1,1,1));
// mirror_body.addShape(shapeB, new CANNON.Vec3(-2,0,0), new CANNON.Quaternion());
// world.addBody(mirror_body);

const createMirror = (width, height, depth, position, index) => {
  const mirrorMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.1,
    metalness: 1,
    envMap: textureCubes[index],
    // envMapIntensity: 1,
    // envMap: environmentMapTexture,
    // refractionRatio:0.5,
    side: THREE.DoubleSide,
  });

  //   const mirrorGeometry = new THREE.CircleGeometry(width * 1.05, 64);
  const mirrorGeometry = new THREE.CylinderGeometry(width, width, 0.125, 64);
  mirrorGeometry.rotateX(Math.PI / 2);

  // Three.js mesh
  const mesh = new THREE.Mesh(mirrorGeometry, mirrorMaterial);

  // mesh.scale.set(width, height, depth);
  mesh._height = height;
  mesh.castShadow = true;

  mesh.position.copy(position);

  // Cannon.js body
  const shape = new CANNON.Box(new CANNON.Vec3(width * 0.7, width * 0.7, depth * 0.5));

  const body = new CANNON.Body({
    mass: 1,
    position: new CANNON.Vec3(position.x, position.y - 2, position.z),
    shape: shape,
    material: defaultMaterial,
    // sleepSpeedLimit :1.0
  });

  /////////////
  const anchor = new CANNON.Body({
    mass: 0,
    position,
    shape: new CANNON.Sphere(1),
  });
  var spring = new CANNON.Spring(body, anchor, {
    localAnchorA: new CANNON.Vec3(0, 1, depth / 2),
    localAnchorB: new CANNON.Vec3(0, 0, 0),
    restLength: 0,
    stiffness: 20,
    damping: 10,
  });
  springs.push(spring);

  mesh.position.copy(body.position);
  mesh.quaternion.copy(body.quaternion);

  mirror_group.add(mesh);
  mirrors.push(mesh);

  // body.addEventListener('collide', playHitSound)
  world.addBody(body);

  // Save in objects
  objectsToUpdate.push({ mesh, body });

  const line_points = [];

  var top_of_mirror = position.y - 2.1 + height / 2;

  line_points.push(new THREE.Vector3(position.x, top_of_mirror, 0));
  line_points.push(new THREE.Vector3(position.x, top_of_mirror + height / 2, 0));

  const line_geometry = new THREE.BufferGeometry().setFromPoints(line_points);
  const line_material = new THREE.LineBasicMaterial({ color: 0xd7d7d7 });
  const line = new THREE.Line(line_geometry, line_material);

  lines.push(line);
  //   scene.add(line);
};

// create mirrors
var mirror_group = new THREE.Group();

//
createMirror(1.5, 1.5, 0.01, { x: -4, y: 4, z: 0 }, 0);
//
createMirror(1.5, 1.5, 0.01, { x: 2.5, y: 3, z: 0 }, 1);
//
createMirror(1, 1, 0.01, { x: -1.7, y: 1.35, z: 1 }, 2);
//
createMirror(2.5, 2.5, 0.01, { x: -3.8, y: -1, z: 0 }, 3);
//
createMirror(3.5, 3.5, 0.01, { x: 3, y: -3, z: 0 }, 4);
//
createMirror(3, 3, 0.01, { x: -5, y: -7.5, z: 0 }, 5);
//
createMirror(1, 1, 0.01, { x: 6.5, y: -7.5, z: 0 }, 6);
//
createMirror(2.5, 2.5, 0.01, { x: 1.5, y: -10, z: 0 }, 7);
//
createMirror(2, 2, 0.01, { x: 4.5, y: -12, z: 1.5 }, 8);
//
createMirror(3, 3, 0.01, { x: -4, y: -14.5, z: 0 }, 9);
//
createMirror(1, 1, 0.01, { x: 1, y: -15, z: 0 }, 10);
//
createMirror(1.5, 1.5, 0.01, { x: 3.7, y: -17, z: 2 }, 11);
//
createMirror(0.8, 0.8, 0.01, { x: -2, y: -19.5, z: 0 }, 12);

scene.add(mirror_group);

// const positionAttribute = mirror0.getAttribute( 'position' );

// const vertex = new THREE.Vector3();
// vertex.fromBufferAttribute( positionAttribute, 0 );

// mirror0.localToWorld( vertex );

//////////

/******* MOUSE MOVE *****/

// Custom global variables
var mouse = { x: -100, y: 0 };

// When the mouse moves, call the given function
document.addEventListener("mousemove", onMouseMove, false);
document.addEventListener("click", onClick, false);
document.addEventListener("mouseup", onMouseUp, false);

// Follows the mouse event
function onMouseMove(event) {
  if (window.innerWidth < 1024) return;
  // Update the mouse variable
  event.preventDefault();
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  // Make the sphere follow the mouse
  var vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
  vector.unproject(camera);
  var dir = vector.sub(camera.position).normalize();
  var distance = -camera.position.z / dir.z;
  var pos = camera.position.clone().add(dir.multiplyScalar(distance));

  // console.log(distance)

  // Make the body follow the mouse
  for (let i = 0; i < spheres_c_arr.length; i++) {
    spheres_c_arr[i].position.copy(pos);
  }
}
function onClick(event) {
  // Update the mouse variable
  // event.preventDefault();
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  // Make the sphere follow the mouse
  var vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
  vector.unproject(camera);
  var dir = vector.sub(camera.position).normalize();
  var distance = -camera.position.z / dir.z;
  var pos = camera.position.clone().add(dir.multiplyScalar(distance));

  // console.log(distance)

  // Make the body follow the mouse
  for (let i = 0; i < spheres_c_arr.length; i++) {
    spheres_c_arr[i].position.copy(pos);
  }
}

function onMouseUp(event) {
  console.log("up");
  if (window.innerWidth < 1024) {
    mouse.x = -1000;
    mouse.y = -1000;
  }

  console.log(mouse.x, mouse.y);
}

// scrollable amount in three
var scrollable_amount = window.innerWidth > 570 ? 18 : 10;
var scroll_percentage = 0;

window.onscroll = pageScroll;

function pageScroll(e) {
  var h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight";
  scroll_percentage = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight);
}

////////// ANIMATE
const clock = new THREE.Clock();
let oldElapsedTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - oldElapsedTime;
  oldElapsedTime = elapsedTime;

  // Update physics
  world.step(1 / 60, deltaTime, 3);

  // for (const object of objectsToUpdate) {
  //     // console.log(object.body)
  //     // object.body.quaternion.setFromAxisAngle(new CANNON.Vec3(0,1,0), oldElapsedTime);

  //     object.mesh.position.copy(object.body.position);
  //     object.mesh.quaternion.copy(object.body.quaternion);

  //     console.log(index)
  //     // object.mesh.geometry.attributes.position.array[52] = 10

  //     //51 = x
  //     //52 = y
  //     //53 = z
  //     // bottom tip x = lines[1].geometry.attributes.position.array[0]
  //     // bottom top y = lines[1].geometry.attributes.position.array[1]
  //     // bottom top z = lines[1].geometry.attributes.position.array[2]
  // }

  for (let i = 0; i < objectsToUpdate.length; i++) {
    // skip first one as mouse cursor is also in array
    // if (i == 0) continue;

    var object = objectsToUpdate[i];

    object.mesh.position.copy(object.body.position);
    object.mesh.quaternion.copy(object.body.quaternion);

    // connect end of lines with the top of circle
    // lines[i - 1].geometry.attributes.position.array[0] = object.mesh.position.x;
    // lines[i - 1].geometry.attributes.position.array[1] = object.mesh.position.y + object.mesh._height / 2 - 0.1;
    // lines[i - 1].geometry.attributes.position.array[2] = object.mesh.position.z;
    // lines[i - 1].geometry.attributes.position.needsUpdate = true;

    // lines[i-1].geometry.attributes.position.array[1] = object.mesh.position.y
    // console.log(object.mesh.position.x)
    // lines[i-1].geometry.attributes.position.array[0] = object.mesh.geometry.attributes.position.array[51]
    // lines[i-1].geometry.attributes.position.array[1] = object.mesh.geometry.attributes.position.array[52]
    // lines[i-1].geometry.attributes.position.array[2] = object.mesh.geometry.attributes.position.array[53]
  }
  // mirror_group.rotation.y += 0.005

  for (let i = 0; i < springs.length; i++) {
    springs[i].applyForce();
  }

  // Update controls
  // controls.update();

  // update stats
  //   stats.update();

  camera.position.y = camera_start_y + -scrollable_amount * scroll_percentage;

  camera.lookAt(0, camera.position.y, -10000);

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

////////// RESIZE
window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  var camera_start_z = window.innerWidth > 570 ? 10 : 20;

  camera.position.z = camera_start_z;

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  //   renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setPixelRatio(1);
});

var intro_split = new SplitText(".intro-text .break", { type: "words", wordsClass: "word" });
var textblock_split = new SplitText(".single-text-block .to-split", { type: "words", wordsClass: "word" });

gsap.set(".intro-text", { opacity: 1 });
gsap.to(".intro-text .word", {
  opacity: 1,
  stagger: 0.2,
  duration: 1.1,
  delay: 2,
  ease: "power1.inOut",
  onComplete: function () {
    // enable intro click
    world.gravity.set(-0.5, -15, 0.5);
    gsap.to(".intro-text .click", {
      opacity: 0.3,
      duration: 1.1,
      delay: 0.6,
      ease: "power1.inOut",
      onComplete: function () {
        document.body.classList.remove("no-click");
        world.gravity.set(0, -15, 0);
      },
    });
  },
});

document.querySelector(".intro").onclick = function () {
  gsap.to(".intro", {
    opacity: 0,
    display: "none",
    duration: 0.6,

    ease: "power1.inOut",
    onComplete: function () {
      init_scrolltrigger();
    },
  });
};

/////////////

// scroll the page on load to remove intiial lag from too mmany cubebox
gsap.to(window, { duration: 0.2, scrollTo: 3000, delay: 1 });

setTimeout(function () {
  gsap.to(window, {
    duration: 0.2,
    scrollTo: 0,
  });
}, 2400);

function init_scrolltrigger() {
  world.gravity.set(0, -15, 0);
  document.body.classList.remove("no-scroll");

  //   gsap.delayedCall(1, playMusic);
  playMusic();

  setTimeout(function () {
    can_choir = true;
    console.log("can choir");
  }, 2000);

  gsap.fromTo(".single-text-block-0 .word", { opacity: 0 }, { opacity: 1, stagger: 0.15, duration: 1, ease: "power1.inOut" });
  gsap.fromTo(".single-text-block-1 .word", { opacity: 0 }, { opacity: 1, stagger: 0.15, duration: 1, delay: 4, ease: "power1.inOut" });

  gsap.utils.toArray(".to-trigger").forEach(function (section) {
    var trigger_var = ScrollTrigger.create({
      trigger: section,
      start: "top 90%",
      once: true,
      onEnter: function onEnter(ev) {
        gsap.fromTo(section.querySelectorAll(".word"), { opacity: 0 }, { opacity: 1, stagger: 0.15, duration: 1, ease: "power1.inOut" });
      },
    });
  });
}

function playMusic() {
  document.querySelector(".speech").play();
}
